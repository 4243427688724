import React, { useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { DigitalWallets } from '@latitude/digital-wallets';

import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import FilterList from '@/components/FilterList/FilterList';
import AdditionalCardsBranded from '@/components/AdditionalCardsBranded/AdditionalCardsBranded';
import { Box } from '@/components/Box/Box';

import PageData from '../../data/pages/credit-cards/index.json';
import { COLOR, BREAKPOINT } from '../../utils/constants';
import BRANDED_CARD_DATA from '../../data/pages/credit-cards/index-branded.json';
import heroImage from '../../images/hero/credit-cards.webp';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import FeeChangesCCSection from './_feeChanges';
import { NotificationBanner } from '@latitude/banner';

function CreditCardsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/gomastercard"
          />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={
              <>
                Latitude <br className="d-lg-none" /> Credit Cards
              </>
            }
            subTitle={
              <>
                Whether you&rsquo;d like a travel card with no international
                transaction or currency conversion fees on purchases, a card
                that offers Interest Free payment plans or a card that earns you
                points every time you spend, you&rsquo;ve come to the right
                place.
              </>
            }
            pageImage={heroImage}
          >
            <small css={{ color: '#fff' }}>T&Cs, fees and charges apply.</small>
          </PageHeaderSection>
        )}

        {/* <FeeChangesCCSection
          heading="Notice of Variation to your credit card contract"
          body={
            <>
              Effective from 17 September 2024, we are changing the annual
              purchase interest rate for{' '}
              <strong>
                Latitude GO Mastercard, Latitude GO Platinum Mastercard,
                Latitude Gem Visa, Latitude 28&deg; Global Platinum Mastercard,
                Latitude Eco Mastercard, Latitude Mastercard
              </strong>{' '}
              and <strong>Latitude Infinity Rewards Visa</strong> from 27.49%
              p.a. to 27.99% p.a.
            </>
          }
          link="https://latitudefs.zendesk.com/hc/en-au/articles/5078660193041-Credit-Card-Pricing-Changes"
        /> */}

        <CardContainer
          isResponsive
          css={`
            margin-top: 30px;
          `}
        >
          <BrandedFilterList
            isBranded
            className="branded"
            data={BRANDED_CARD_DATA.cards}
            selectedItem={PageData.categoryList}
            css={`
          &&{
            "position:relative;
            z-index:3"
          }

          [class^='card-media__buttons'] a{
            width: auto;
            max-width: unset;
            padding-left: 15px;
            padding-right: 15px;
          }
          `}
          />
          <br />
        </CardContainer>

        <Box css="position:relative;">
          <AngleWhite />
          <div css="position:relative; z-index:1; font-size: 13px">
            <AdditionalCardsBranded />
          </div>
        </Box>

        <DigitalWallets
          heading="Digital wallets"
          subheading="You can pay any way on your phone or wearable tech device with just a tap."
        />

        <ImportantInformation
          data={require('../../data/pages/credit-cards/index.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          css={`
            && {
              background-color: ${COLOR.GREY10};
            }
          `}
        />
      </main>
    </Layout>
  );
}

const CardContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
`;

const BrandedFilterList = styled(FilterList)`
  .dropdown-container-fixed {
    // mobile version
    width: 100%;
    max-width: 400px;
    margin-left: 0;
  }

  .dropdown::-ms-expand {
    display: none;
  }

  .dropdown-container {
    // desktop version
    position: absolute;
    left: 0;
    display: block;
    width: 255px;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    &:last-child {
      padding-top: 100px;
    }
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    && div.lfs-grid-layout div.lfs-branded-card-grid:nth-child(3) {
      margin-top: 0;
    }
    && div.lfs-grid-layout div.lfs-branded-card-grid:nth-child(5) {
      margin-top: -50px;
    }
  }
`;

const AngleWhite = styled.div`
  position: absolute;
  width: 100%;
  height: 300%;
  background-color: #fff;
  transform: skew(0deg, 4deg);
  transform-origin: top right;
`;

export default CreditCardsPage;
